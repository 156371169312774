import * as React from "react"
import { Layout } from "@components/layout";
import styled from "styled-components";
import { ButtonEnum } from "src/enums/buttonsEnum";
import { BrandButton } from "@components/reusables/brandButton";
import { color } from "@styles/styleVariables";
import { HeadMetadata } from "@components/headMetadata";

const NotFoundPage = () => (
  <Layout>
    <HeadMetadata location="404"></HeadMetadata>
    <SNotFoundPage className="content-wrapper">
      <h1>OOPS!</h1>
      <p>Somethings wrong here.</p>
      <BrandButton
        link="/"
        name="Back to Home"
        id="NotFoundPage"
        category={ButtonEnum.Tertiary}
      ></BrandButton>
    </SNotFoundPage>
  </Layout>
);

const SNotFoundPage = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: ${color.oxfordBlue};
  h1,p {
    text-align: center;
    color: ${color.culture};
    margin-bottom: 1rem;
  }
`;

export default NotFoundPage;